import isVisible from "./IsVisible";

/**
 *
 */

class OnScroll {
    constructor(element = document) {
        this.element = element;
        element.addEventListener("scroll", this.update, { passive: true });
    }

    unmount() {
        this.element.removeEventListener("scroll", this.update);
    }

    update = () => {
        const anchors = this.element.querySelectorAll(
            "[data-on-scroll-target]"
        );

        let alreadyVisible = null;
        for (let i = 0; i < anchors.length; i++) {
            const visible = isVisible(this.element, anchors[i]);

            const onScrollId = anchors[i].getAttribute("data-on-scroll-target");

            const targetItems = this.element.querySelectorAll(
                `[data-on-scroll-id="${onScrollId}"]`
            );
            if (!targetItems) continue;

            if (visible && !alreadyVisible) {
                targetItems.forEach((item) => {
                    item.classList.add("on-scroll--active");
                });
                alreadyVisible = visible;
            } else {
                targetItems.forEach((item) => {
                    item.classList.remove("on-scroll--active");
                });
            }
        }
    };
}

export default OnScroll;
