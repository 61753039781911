import React from "react";

import telmaIllustration from "../images/illustrations/telma.svg";
import conversationalAiIllustration from "../images/illustrations/conversational-ai.svg";
import dataTotextIllustration from "../images/illustrations/data-to-text.svg";
import speechSolutionsIllustration from "../images/illustrations/speech-solutions.svg";

const ProductsSection = (props) => {
    return (
        <section className="products-section section">
            <div id="products-section-anchor" className="section__anchor" />
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-md-6 products-section__left-column">
                        <div className="products-section__header">
                            <h1 className="products-section__heading">
                                Products
                            </h1>
                            <p>
                                Building customer solutions in conversational
                                AI, speech recognition, speech synthesis,
                                machine translation, telephony, AIOps.
                            </p>
                        </div>
                        <ul
                            id="products-menu"
                            className="vertical-menu products-section__vertical-menu"
                        >
                            <li className="vertical-menu__item">
                                <a
                                    data-on-scroll-id="conversational-ai"
                                    href="#Conversational-ai"
                                >
                                    Conversational AI
                                </a>
                            </li>
                            <li className="vertical-menu__item">
                                <a
                                    data-on-scroll-id="speech-solutions"
                                    href="#Speech-solutions"
                                >
                                    Speech solutions
                                </a>
                            </li>
                            <li className="vertical-menu__item">
                                <a data-on-scroll-id="telma" href="#Telma">
                                    Telma
                                </a>
                            </li>
                            <li className="vertical-menu__item">
                                <a
                                    data-on-scroll-id="data-to-text"
                                    href="#Data-to-text"
                                >
                                    Data to text
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 h-100 products-section__right-column">
                        <div className="products-section__right-column-wrapper">
                            <section
                                id="Conversational-ai"
                                className="products-section__product"
                            >
                                <div>
                                    <img
                                        className="products-section__product-image"
                                        src={conversationalAiIllustration}
                                        alt="Conversational AI illustration"
                                    />
                                    <h2
                                        className="products-section__product-heading"
                                        data-on-scroll-target="conversational-ai"
                                    >
                                        Conversational AI
                                    </h2>
                                </div>
                                <p>
                                    Designing, building, improving, and
                                    maintaining smart text-based or voice-based
                                    assistants using scalable conversational
                                    automation platform. On-demand solution or
                                    integration with conversational services
                                    such as IBM Watson Assistant. Easy
                                    integration into existing backends or legacy
                                    systems. Design based on detailed analysis
                                    of customer needs, iterative improvements
                                    based on deep analysis of user traffic.
                                </p>
                            </section>
                            <section
                                id="Speech-solutions"
                                className="products-section__product"
                            >
                                <div>
                                    <img
                                        className="products-section__product-image"
                                        src={speechSolutionsIllustration}
                                        alt="Speech solutions illustration"
                                    />
                                    <h2
                                        className="products-section__product-heading"
                                        data-on-scroll-target="speech-solutions"
                                    >
                                        Speech solutions
                                    </h2>
                                </div>
                                <p>
                                    Transcribe spoken speech into text in real
                                    time, synthesize natural sounding audio from
                                    text. Boost performance on specific
                                    enterprise domains with custom models. Scan
                                    vast volumes of data with keyword spotting.
                                    Prosodic, emotional, and biometric features.
                                    On-demand solution or integration with
                                    speech services such as IBM Watson Speech To
                                    Text and Text To Speech.
                                </p>
                            </section>
                            <section
                                id="Telma"
                                className="products-section__product"
                            >
                                <div>
                                    <img
                                        className="products-section__product-image"
                                        src={telmaIllustration}
                                        alt="Telma illustration"
                                    />
                                    <h2
                                        className="products-section__product-heading"
                                        data-on-scroll-target="telma"
                                    >
                                        Telma
                                    </h2>
                                </div>
                                <p>
                                    Conversational intelligence platform for
                                    omnichannel solutions. Deep understanding of
                                    human multi-modal interactions. Easy
                                    integration via REST API, into existing
                                    backends or legacy systems. We use several
                                    sophisticated strategies to interact with
                                    customers over the respective channels in
                                    the true omnichannel way and thus maximally
                                    optimize the user experience.
                                </p>
                            </section>
                            <section
                                id="Data-to-text"
                                className="products-section__product"
                            >
                                <div>
                                    <img
                                        className="products-section__product-image"
                                        src={dataTotextIllustration}
                                        alt="Data to text illustration"
                                    />
                                    <h2
                                        className="products-section__product-heading"
                                        data-on-scroll-target="data-to-text"
                                    >
                                        Data to text
                                    </h2>
                                </div>
                                <p>
                                    Generate attractive written or spoken
                                    reports from structured data sources.
                                    Customize and fine-tune for your audience,
                                    target modality or platform. Easily embed
                                    dynamic content into your presentation.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductsSection;
