/**
 *
 * @param [scrollableElement = document] - scrollable container
 * @param element - element inside the scrollable container
 * @topOffset {number} - offset in -y direction from element top
 * @bottomOffset {number} - offset in +y direction from element bottom
 * @returns {boolean} - is for telling whether an element is (partially) visible or not
 */


export default (scrollableElement, element, topOffset = 0, bottomOffset = 0) => {
    const viewportHeight = window.innerHeight;

    const scrollTop = scrollableElement.documentElement ?
      scrollableElement.documentElement.scrollTop: scrollableElement.scrollTop;

    const elementRect = element.getBoundingClientRect();

    const topReached = (elementRect.y - topOffset) < viewportHeight;
    const bottomIsOut = elementRect.bottom + bottomOffset < 0;

    return topReached && !bottomIsOut;
}
