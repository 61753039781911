import React from "react";
import PropTypes from "prop-types";
import "./MemberTile.scss";

const MemberTile = (props) => {
    const { name, image } = props;
    const textBlockClass =
        name === "COMING SOON" ? "coming-soon text-block" : "text-block";

    return (
        <div className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-2 image-block">
            <div className="image-block__image">
                {image}
                <div className="image-block__overlay"></div>
            </div>
            <div className={textBlockClass}>
                <h5 className="image-block__name">{name}</h5>
            </div>
        </div>
    );
};

MemberTile.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.node.isRequired,
};

export default MemberTile;
