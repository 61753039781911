import React from "react";
import linkedinLogo from "../images/linkedin-square-color.png";

const SalesSection = () => (
    <section id="sales-section" className="section section--light">
        <div id="sales-section-anchor" className="section__anchor" />
        <div className="container">
            <span className="fw-bold">Contact our sales
            by phone:</span> +420 737 264 326,
            by e-mail:&nbsp;
            <a
                            className="link--inverse"
                            href="mailto:sales@themama.ai"
                        >
                            sales@&#8203;themama.ai
                        </a>

            , or follow us on
            <a
                            href="https://www.linkedin.com/company/the-mama-ai/"
                            aria-label="Follow us on Linkedin.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-media-icon"
                            title="The Mama AI, SE"
                        >
                            <img
                                src={linkedinLogo}
                                alt="Linkedin logo"
                                className="img-responsive"
                                width="25px"
                                height="25px"
                            />
                        </a>
        </div>
    </section>
);

export default SalesSection;
