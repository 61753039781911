import React from "react";
import MemberTile from "./MemberTile";
import useSwr from "swr";
import { getPhotoUrl } from "./utils/getPhotoUrl";

const TeamSection = () => {
    const { data } = useSwr(
        "/api/team-members/?populate=photo&pagination[pageSize]=100"
    );

    const teamMembers = data?.data || [];

    const images = teamMembers.map((member) => {
        const { photo, name } = member.attributes;

        return {
            name: name,
            image: (
                <img
                    src={getPhotoUrl(photo.data.attributes)}
                    className="image"
                    alt={photo.data.attributes.alternativeText}
                />
            ),
        };
    });

    return (
        <section className="our-team-section section section--light">
            <div id="our-team-section-anchor" className="section__anchor" />
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>
                            {" "}
                            Almost like a family, certainly a strong team.{" "}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <p>
                            Before some of us uttered their first word, others
                            worked to make computers able to recognize it.
                        </p>
                    </div>
                </div>
                <div className="row gy-2 gx-2 team-grid">
                    {images.map((val, k) => {
                        return (
                            <MemberTile
                                key={`${val}_${k}`}
                                name={val.name}
                                description={val.desc}
                                image={val.image}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default TeamSection;
